<template>
  <div class="padding24 color_white">
    <div v-if="isShow">
      <div class="head-box">
        <a-form layout="inline">
          <a-form-item label="用户手机号">
            <a-input v-model="filter.userName" placeholder="请输入用户手机号" />
          </a-form-item>
          <a-form-item label="当前积分范围">
            <a-select v-model="integralDefaultVal" :default-value="integralDefaultVal" style="width: 120px" @change="handleSelectChange">
              <a-select-option v-for="item in integralInterval" :value="item" :key="item">
                {{ item}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="积分状态">
            <a-select v-model="filter.status" :default-value="filter.status" style="width: 120px" >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option :value="1">正常</a-select-option>
              <a-select-option :value="2">已冻结</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button icon="search" type="primary" @click="searchClick" :disabled="tableLoading">
              搜索
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-button icon="redo" type="primary" @click="clearSearch">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="UserIntegralListColumns"
        :data-source="tabledData"
        :pagination="false"
        :loading="tableLoading"
        :row-key="record => record.userNo"
        @change="tableChange"
      >
        <span slot="status" slot-scope="text, record">{{ record.status === 1 ? '正常' : '已冻结'}}</span>
        <span slot="actions" slot-scope="text, record">
          <a v-if="operation.edit" @click="editClick(record)" style="margin-right: 10px">编辑</a>
          <a v-if="operation.frozen" style="margin-right: 10px" @click="onChangeStatus(record)">{{ record.status === 1 ? '冻结' : '解冻'}}</a>
          <router-link v-if="operation.check" :to="`/operateCenter/userIntegralList/detail/${record.userNo}`">查看变更</router-link>
        </span>
      </a-table>
      <MyPagination
        :count="total"
        :pageNo="pageNo"
        @showSizeChangeFn="paginationCallBack"
        v-show="!tableLoading"
      />
      <EditIntegral :visible="showEditIntegral" :record="currentRecord" @cancel="showEditIntegral = false" @fresh="fetchData" />
      <!-- v-if="updateStatusModal" -->
      <UpdateIntegralStatus v-if="updateStatusModal" :visible="updateStatusModal" :record="currentRecord" @cancel="updateStatusModal = false" />
    </div>
    <router-view v-else />
  </div>  
</template>

<script>
import { UserIntegralListColumns } from '../constants'
import MyPagination from '@/components/pagination/MyPagination'
import { getCreditListAPI } from '@/request/api/operateCenter'
import { codeFn } from '@/utils/tools'
import EditIntegral from '../components/editIntegral.vue'
import UpdateIntegralStatus from '../components/updateIntegralStatus.vue'
const integralInterval = [
  '[0, 500]',
  '(500, 1000]',
  '(1000, 3000]',
  '(3000, 5000]',
  '(5000, +∞]',
  '全部'
]
export default {
  name: 'UserIntegralList',
  components: { MyPagination, EditIntegral, UpdateIntegralStatus },
  data() {
    return {
      integralInterval,
      UserIntegralListColumns,
      tabledData: [],
      total: 0,
      tableLoading: true,
      pageNo: 1,
      pageSize: 20,
      operation: {
        edit: false,
        frozen: false,
        check: false
      },
      integralDefaultVal: integralInterval[integralInterval.length - 1],
      filter: {
        userName: '',
        status: 0
      },
      showEditIntegral: false,
      updateStatusModal: false,
      currentRecord: {}
    }
  },
  computed: {
    isShow() {
      return this.$route.meta.isShow
    }
  },
  created() {
    this.operation.edit = !!codeFn('userIntegralList/edit')
    this.operation.frozen = !!codeFn('userIntegralList/frozen')
    this.operation.check = !!codeFn('userIntegralList/check')
    this.fetchData()
  },
  methods: {
    onChangeStatus(record) {
      this.currentRecord = record
      this.updateStatusModal = true
    },
    tableChange(pagination, filters, sorter) {
      const { columnKey, order } = sorter
      this.filter.sort = order === 'descend' ? columnKey + ' desc' : columnKey + ' asc'
      if (!order) delete this.filter.sort
      this.fetchData()
    },
    handleSelectChange(val) {
      const value = val.replace(/\(/g, '').replace(/\[/g, '').replace(/\]/g, '')
      const arr = value.split(',')
      if (val === '全部') {
        delete this.filter.max
        delete this.filter.min
        return
      }
      this.filter.min = +arr[0].trim()
      this.filter.max = +arr[1].trim()
      if (isNaN(this.filter.max)) delete this.filter.max
    },
    async fetchData() {
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        ...this.filter
      }
      const { code, data } = await getCreditListAPI(params)
      this.tableLoading = false
      if (code === 7502) {
        this.tabledData = []
        this.total = 0
      }
      if (code !== 200) return
      this.tabledData = data.list
      this.total = data.count
    },
    paginationCallBack(current, pageSize) {
      this.pageNo = current
      this.pageSize = pageSize
      this.fetchData()
    },
    editClick(record) {
      this.currentRecord = record
      this.showEditIntegral = true
    },
    searchClick() {
      this.pageNo = 1
      this.fetchData()
    },
    clearSearch() {
      this.integralDefaultVal = this.$options.data().integralDefaultVal
      this.filter = this.$options.data().filter
    }
  }
}
</script>