<template>
  <div>
    <a-modal :visible="visible" title="修改积分数量" destroyOnClose @ok="handleOk" @cancel="onCancel">
      <a-form layout="inline" class="info-box">
        <a-form-item label="用户姓名">
          {{ record.nickName }}
        </a-form-item>
        <a-form-item label="用户手机号">
          <span>{{ record.userName }}</span>
        </a-form-item>
        <a-form-item label="可用积分">
         {{ record.totalCredit}}
        </a-form-item>
      </a-form>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="填写积分数量" prop="number">
          <a-select v-model="form.type" style="width: 80px; margin-right: 10px" >
            <a-select-option :value="1">增</a-select-option>
            <a-select-option :value="2">减</a-select-option>
          </a-select>
          <a-input-number v-model="form.number" :min="0" />
        </a-form-model-item>
        <a-form-model-item label="修改后的可用积分" prop="count">
          {{ form.type === 1 ? record.totalCredit + form.number : record.totalCredit - form.number  }}
        </a-form-model-item>
        <a-form-model-item label="修改理由" prop="reason">
          <a-textarea v-model="form.reason" placeholder="请填写修改积分数值的理由或凭据，未经授权擅自修改，后果自负" :rows="3"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { creditChangeAPI } from '@/request/api/operateCenter'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: ()  => ({})
    }
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      rules: {
        number: [{ required: true, message: '请设置合理的积分', min:1, type:'number', trigger: ['blur', 'change'] }],
        reason: [{ required: true, message: '请输入修改理由', trigger: ['blur', 'change'] }]
      },
      form: {
        type: 1,
        number: 0,
        reason: ''
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
      this.form = this.$options.data().form
    },
    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return
        const params = {
          userNo: this.record.userNo,
          ...this.form
        }
        const { code } = await creditChangeAPI(params)
        if (code !== 200) return
        this.$message.success('修改成功')
        this.onCancel()
        this.$emit('fresh')
      })
    },
  }
}
</script>

<style lang="less" scoped>
.info-box {
  margin-bottom: 10px;
}
/deep/.ant-form-item {
  margin-bottom: 10px;
}
</style>