<template>
  <a-modal :title="title" :visible="visible" @ok="handleOk" @cancel="$emit('cancel')">
    <p class="content">{{ content }}</p>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="操作理由" prop="reason">
        <a-textarea v-model="form.reason" :placeholder="placeholder" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { updateCreditStatusAPI } from '@/request/api/operateCenter'
import { Modal } from 'ant-design-vue'
export default {
  name: 'updateIntegralStatus',
  props: {
    record: {
      type: Object,
      default: {}
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      title: `您确定要${this.record?.status === 1 ? '冻结' : '解冻'}此用户的积分吗`,
      content: this.record?.status === 1 ? '冻结后，用户无法获取、修改、消费积分等一切操作。' : '解冻后，用户积分可正常使用。',
      form: {
        reason: ''
      },
      placeholder: '请输入操作理由，未经授权擅自修改，后果自负。',
      rules: {
        reason: [{ required: true, message: '请输操作理由', trigger: ['blur', 'change'] }]
      }
    }
  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return
        this.record.status = this.record.status === 1 ? 2 : 1
        const params = {
          userNo: this.record.userNo,
          status: this.record.status,
          reason: this.form.reason
        }
        const { code } = await updateCreditStatusAPI(params)
        if (code !== 200) return
        this.$emit('cancel')
        console.log(this.record.status)
        const msg = this.record.status === 2 ? '冻结后，用户无法获取、修改、消费积分等一切操作。' : '解冻后积分可被正常使用'
        Modal.success({
          title: `积分已${this.record.status === 2 ? '冻结' : '解冻'}`,
          content: msg
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  margin-bottom: 20px;
  text-align: center;
}
</style>